@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
body {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0b0611;
}

p,
span {
  font-family: 'Roboto Mono', monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  color: rgb(247, 247, 250);
  text-align: left;
}

h1 {
  color: rgb(247, 247, 250);
  font-size: 24px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.content {
  max-width: 800px;
  width: 100%;
  background-color: #16121f;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px 35px 0px;
}

.privatekey {
  display: flex;
  margin-top: 30px;
  max-width: 500px;
  width: 100%;
  justify-content: space-between;
}

.proxy {
  margin-top: 30px;
}

.withdraw {
  display: flex;
  margin-top: 30px;
  max-width: 500px;
  width: 100%;
  justify-content: space-between;
}

.recipient {
  margin-top: 50px;
}

.privatekey > input {
  background-color: #23202f;
  border-radius: 16px;
  color: rgb(247, 247, 250);
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  border: none;
  outline: none;
  max-width: 295px;
  width: 100%;
  height: 50px;
  padding: 0px 20px;
}

button {
  margin-left: 40px;
  background-color: rgba(104, 107, 130, 0.12);
  color: rgb(148, 151, 169);
  border: none;
  border-radius: 16px;
  cursor: pointer;
  max-width: 150px;
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  transition: all 200ms ease-in-out;
}

button:hover {
  background-color: rgba(104, 107, 130, 0.24);
  color: rgb(247, 247, 250);
}

@media (max-width: 768px) {
  .content {
    width: 90%;
    box-sizing: border-box;
    padding: 10px;
  }
}
